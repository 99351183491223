import chai from "chai";
import store from "@/store/index";
import {} from "@/clients/tracelight";
export function testDarkReconDashboard() {
  const testCases = [];
  testCases.push((checkTest) => {
    store.dispatch("darkRecon/getTotals").then((rDarkReconTotals) => {
      checkTest(
        () => {
          chai.expect(rDarkReconTotals).to.be.a("object");
          chai.expect(rDarkReconTotals).to.have.property("num_authors");
        },
        `Dark Recon Dashboard totals should be an object with num_authors included`,
        rDarkReconTotals
      );
    });

    store
      .dispatch("darkRecon/trendsSearch", {
        key: "queryStringTrends1",
        value: "bank",
      })
      .then((rDarkReconTrendsSearch) => {
        checkTest(
          () => {
            chai.expect(rDarkReconTrendsSearch).to.be.a("object");
            chai.expect(rDarkReconTrendsSearch).to.have.property("authors");
          },
          `Dark Recon Dashboard trends search should be an object with authors included`,
          rDarkReconTrendsSearch
        );
      });

    store
      .dispatch("darkRecon/dashboardSearch", {
        key: "queryStringDashboard",
        value: "forum",
      })
      .then((rDarkReconDashboardSearch) => {
        checkTest(
          () => {
            chai.expect(rDarkReconDashboardSearch).to.be.a("array");
          },
          `Dark Recon Dashboard search should be an array`,
          rDarkReconDashboardSearch
        );
      });
  });
  return testCases;
}
