import chai from "chai";
import store from "@/store/index";
import {
  listReports,
  reportTypesGet,
  createReport,
  getReport,
  getReportArchive,
} from "@/clients/tracelight";
export function testClientReports() {
  const testCases = [];
  testCases.push((checkTest) => {
    const payload = {};
    store.dispatch("customerList/getCustomers").then((rCustomerList) => {
      reportTypesGet(payload, {})
        .then(function (reportTypes) {
          checkTest(
            () => {
              chai.expect(reportTypes.data).to.be.a("array");
              chai.expect(reportTypes.data[0]).to.be.a("object");
            },
            "Report Types should be an array of objects",
            reportTypes.data
          );

          const customer = rCustomerList.filter(
            (customer) => customer.name == "GroupSense"
          );
          const param = { customer_id: customer[0].external_id };
          const params = {
            customer_id: customer[0].external_id,
            report_type: reportTypes.data[0].report_type,
          };
          createReport(payload, params)
            .then((createdReport) => {
              checkTest(
                () => {
                  chai.expect(createdReport.data).to.have.property("reportId");
                  chai
                    .expect(createdReport.data)
                    .to.have.property("reportInstance");
                  chai.expect(createdReport.data).to.have.property("status");
                },
                "Creation message should include report ID, report instance, and status properties",
                createdReport.data
              );
            })
            .catch(function (error) {
              console.error(error);
              alert(error);
            });

          listReports(payload, param)
            .then(function (reports) {
              checkTest(
                () => {
                  chai.expect(reports.data).to.be.a("array");
                  chai.expect(reports.data[0]).to.be.a("object");
                },
                "Customer reports should be an array of objects",
                reports.data
              );

              for (let report of reports.data) {
                const para = {
                  customer_id: customer[0].external_id,
                  report_type: report.report_type,
                  report_id: report.report_id,
                };
                if (report.completed_at !== null) {
                  getReport(payload, para)
                    .then((reportInstance) => {
                      if (reportInstance.data) {
                        if (reportInstance.data.summary) {
                          for (const [key, value] of Object.entries(
                            reportInstance.data.summary
                          )) {
                            if (reportInstance.data.summary[key].csv) {
                              for (const [k, v] of Object.entries(
                                reportInstance.data.summary[key].csv
                              )) {
                                const par = {
                                  customer_id: customer[0].external_id,
                                  report_type: report.report_type,
                                  report_id: report.report_id,
                                  component: key,
                                  csvtype: k,
                                };

                                getReport(payload, par)
                                  .then((reportInstanceCsv) => {
                                    checkTest(
                                      () => {
                                        chai
                                          .expect(reportInstanceCsv.data)
                                          .to.have.property("reportId");
                                        chai
                                          .expect(reportInstanceCsv.data)
                                          .to.have.property("reportInstance");
                                        chai
                                          .expect(reportInstanceCsv.data)
                                          .to.have.property("s3");
                                        chai
                                          .expect(reportInstanceCsv.data.s3)
                                          .to.have.property("key");
                                        chai
                                          .expect(reportInstanceCsv.data.s3)
                                          .to.have.property("bucket");
                                        chai
                                          .expect(reportInstanceCsv.data.s3)
                                          .to.have.property("url");
                                        chai
                                          .expect(reportInstanceCsv.data.s3.url)
                                          .to.include(".csv");
                                      },
                                      `Retrieve specified report with ID ${report.report_id} and should include S3 URL property with csv file`,
                                      reportInstanceCsv.data
                                    );
                                  })
                                  .catch(function (error) {
                                    console.error(error);
                                    alert(error);
                                  });
                              }
                            }
                          }
                        }
                      }
                    })
                    .catch(function (error) {
                      console.error(error);
                      alert(error);
                    });

                  getReportArchive(payload, para)
                    .then((reportArchive) => {
                      checkTest(
                        () => {
                          chai
                            .expect(reportArchive.data)
                            .to.have.property("reportId");
                          chai
                            .expect(reportArchive.data)
                            .to.have.property("reportInstance");
                          chai
                            .expect(reportArchive.data)
                            .to.have.property("s3");
                          chai
                            .expect(reportArchive.data.s3.key)
                            .to.include(".zip");
                        },
                        `Retrieve archived specified report with ID ${report.report_id} and should include S3 Key property with zip file`,
                        reportArchive.data
                      );
                    })
                    .catch(function (error) {
                      console.error(error);
                      alert(error);
                    });
                }
              }
            })
            .catch(function (error) {
              console.error(error);
              alert(error);
            });
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
        });
    });
  });

  return testCases;
}
