import chai from "chai";
import store from "@/store/index";
import {
  breachesSearchTermsPost,
  breachesSearchPost,
  breachesSearchCountPost,
  breachesSearchDownloadPost,
} from "../../clients/tracelight";
export function testBreachResearch() {
  const testCases = [];
  testCases.push((checkTest) => {
    let params = {};
    let payload = {
      term: "bank",
    };
    breachesSearchTermsPost(payload, params).then(function (result) {
      checkTest(
        () => {
          chai.expect(result.data).to.be.a("object");
          chai.expect(result.data).to.have.property("data_type");
        },
        "Breach Research search term results should be an object with data_type specified",
        result.data
      );

      breachesSearchPost(
        [{ types: [result.data.data_type], terms: result.data.terms }],
        params
      ).then(function (res) {
        checkTest(
          () => {
            chai.expect(res.data).to.be.a("array");
          },
          "Breach Research search results should be an array of objects",
          res.data
        );
      });

      breachesSearchDownloadPost(
        [{ types: [result.data.data_type], terms: result.data.terms }],
        params
      ).then(function (res) {
        checkTest(
          () => {
            chai.expect(res.data).to.be.a("object");
            chai.expect(res.data).to.have.property("url");
          },
          "Breach Research search download post results should return a url for download",
          res.data
        );
      });

      breachesSearchCountPost(
        [{ types: [result.data.data_type], terms: result.data.terms }],
        params
      ).then(function (r) {
        checkTest(
          () => {
            chai.expect(r.data).to.be.a("object");
          },
          "Breach Research search count should be an object",
          r.data
        );
      });
    });
  });
  return testCases;
}
