<template>
  <div>
    <v-alert height="80" dark width="100%" prominent class="mb-8">
      <v-row class="d-flex no-gutters">
        <v-col cols="6">
          <h2 class="mt-1 font-weight-light">Tests</h2>
        </v-col>
        <v-col cols="3">
          <v-select
            dense
            class="mt-1"
            label="Choose Test"
            :items="testNames"
            v-model="selectedName"
            single-line
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-btn
            color="primary"
            class="mt-1 ml-6"
            text
            @click="runTests(selectedValue)"
          >
            Run Tests</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-expansion-panels focusable>
      <v-expansion-panel v-for="(result, index) in testResults" :key="index">
        <v-expansion-panel-header>
          <v-row>
            {{ result.desc }} &nbsp; &nbsp;
            <v-icon v-if="result.passes == true" color="green" class="mt-n1"
              >mdi-check-bold</v-icon
            >
            <v-icon v-if="result.passes == false" color="red" class="mt-n1"
              >mdi-close-thick</v-icon
            ></v-row
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <pre class="mt-4" style="overflow-x: auto">{{ result.output }}</pre>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { testClientIdentity } from "./testClientIdentity.js";
import { testCustomerList } from "./testCustomerList.js";
import { testDigitalFootprint } from "./testDigitalFootprint.js";
import { testDarkRecon } from "./testDarkRecon.js";
import { testBreachResearch } from "./testBreachResearch.js";
import { testScrapingResults } from "./testScrapingResults.js";
import { testUserProfile } from "./testUserProfile.js";
import { testBreachDashboard } from "./testBreachDashboard.js";
import { testDarkReconDashboard } from "./testDarkReconDashboard.js";
import { testClientReports } from "./testClientReports.js";
import { testThreatActors } from "./testThreatActors.js";
export default {
  name: "Tests",
  data: () => ({
    testResults: [],
    tests: [
      { name: "Client Identity", value: testClientIdentity() },
      { name: "Client List", value: testCustomerList() },
      { name: "Client Reports", value: testClientReports() },
      { name: "Digital Footprint", value: testDigitalFootprint() },
      { name: "User Profile", value: testUserProfile() },
      { name: "Scraping Results", value: testScrapingResults() },
      { name: "Breach Research", value: testBreachResearch() },
      { name: "Breach Dashboard", value: testBreachDashboard() },
      { name: "Dark Recon", value: testDarkRecon() },
      { name: "Dark Recon Dashboard", value: testDarkReconDashboard() },
      { name: "Threat Actors", value: testThreatActors() },
    ],
    selectedName: null,
    selectedValue: null,
  }),
  computed: {
    testNames() {
      return this.tests.map((x) => x.name);
    },
  },
  methods: {
    checkTest(assertion, desc, output) {
      try {
        assertion();
        this.addResult(desc, output, true);
      } catch {
        this.addResult(desc, output, false);
      }
    },

    addResult(desc, output, passes) {
      this.testResults.push({ desc, output, passes });
    },

    runTests(type) {
      if (type == null) {
        alert("Please choose a test first!");
        return;
      }
      this.testResults = [];
      const testCases = [];
      testCases.push(type);
      for (const testCaseCollection of testCases) {
        for (const testCase of testCaseCollection) {
          testCase(this.checkTest);
        }
      }
    },
  },
  watch: {
    selectedName: function (val) {
      if (val !== null) {
        const value = this.tests.filter((x) => x.name == val);
        this.selectedValue = value[0].value;
      }
    },
  },
};
</script>
<style>
.customSelectInput {
  border: solid 1px #d5d5d5;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background: transparent;
  color: #fff;
}
</style>
