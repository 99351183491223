import chai from "chai";
import store from "@/store/index";
import { getScrapingResults } from "@/clients/tracelight";
export function testScrapingResults() {
  const testCases = [];
  testCases.push((checkTest) => {
    store.dispatch("scrapingResult/getResults").then((rScrapingResults) => {
      checkTest(
        () => {
          chai.expect(rScrapingResults).to.be.a("array");
          chai.expect(rScrapingResults[0]).to.be.a("object");
        },
        "Scraping results should be an array of objects",
        rScrapingResults
      );
      const payload = {};

      for (let scraping of rScrapingResults) {
        const params = { data_id: scraping.external_id };
        getScrapingResults(payload, params).then((result) => {
          checkTest(
            () => {
              chai.expect(result.data).to.be.a("object");
            },
            `Scraping result ID ${scraping.external_id} details should be an object`,
            result.data
          );
        });

        store
          .dispatch("scrapingResult/downloadImages", scraping.external_id)
          .then((res) => {
            checkTest(
              () => {
                chai.expect(res).to.be.a("object");
                chai.expect(res).to.have.property("download_urls");
              },
              `Scraping result ID ${scraping.external_id} download images should be an object returning the download urls`,
              res
            );
          });
      }
    });
  });

  return testCases;
}
