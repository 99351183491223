import chai from "chai";
import store from "@/store/index";
import {
  statisticsBreachesGet,
  statisticsBreachesTotalGet,
  statisticsBreachesStatsIdGet,
} from "@/clients/tracelight";
export function testBreachDashboard() {
  const testCases = [];
  testCases.push((checkTest) => {
    let payload = {};

    statisticsBreachesGet(payload, {
      deep: true,
    })
      .then(function (result) {
        console.debug(result);
        checkTest(
          () => {
            chai.expect(result.data).to.be.a("array");
          },
          "Breach Dashboard statistics search results should be in an array",
          result.data
        );
      })
      .catch(function (error) {
        console.error(error);
      });

    statisticsBreachesTotalGet(payload, {
      deep: true,
    })
      .then(function (res) {
        console.debug(res);
        checkTest(
          () => {
            chai.expect(res.data).to.be.a("array");
          },
          "Breach Dashboard statistics total should be in an array",
          res.data
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  });

  return testCases;
}
