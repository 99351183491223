import chai from "chai";
import store from "@/store/index";
import {
  userIdGet,
  apiKeyTypes,
  usersKeyGet,
  usersKeyPost,
} from "@/clients/tracelight";
export function testUserProfile() {
  const testCases = [];
  testCases.push((checkTest) => {
    const userId = window.localStorage.getItem("userId");
    let params = { user_id: userId };
    let payload = {};

    userIdGet(payload, params)
      .then((r) => {
        checkTest(
          () => {
            chai.expect(r.data).to.be.a("object");
          },
          "User Profile details should be an object",
          r.data
        );
      })
      .catch(function (error) {
        console.error(error);
      });

    apiKeyTypes({}, {})
      .then((res) => {
        checkTest(
          () => {
            chai.expect(res.data).to.be.a("array");
            chai.expect(res.data[0]).to.be.a("object");
          },
          "API Key Types should be an array of objects",
          res.data
        );

        for (let k of res.data) {
          const pay = { type: k.id };

          usersKeyPost(pay, params)
            .then((result) => {
              checkTest(
                () => {
                  chai.expect(result.data).to.be.a("object");
                },
                `User API Key Post ${k.name} type should be an object`,
                result.data
              );
            })
            .catch(function (error) {
              console.error(error);
            });
        }

        usersKeyGet(payload, params)
          .then((re) => {
            checkTest(
              () => {
                chai.expect(re.data).to.be.a("array");
              },
              "User Key details should be in an array",
              re.data
            );
          })
          .catch(function (error) {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  });
  return testCases;
}
