import chai from "chai";
import store from "@/store/index";
import { darkreconSearchStatsGet } from "../../clients/tracelight";
export function testDarkRecon() {
  const testCases = [];
  testCases.push((checkTest) => {
    store.commit("darkRecon/replaceValue", {
      key: "queryString",
      value: "bank",
    });
    store
      .dispatch("darkRecon/update", { queryString: "bank" })
      .then((rDarkReconResults) => {
        checkTest(
          () => {
            chai.expect(rDarkReconResults).to.be.a("array");
            chai.expect(rDarkReconResults[0]).to.be.a("object");
          },
          "Dark recon search results should be an array of objects",
          rDarkReconResults
        );

        for (let drResult of rDarkReconResults) {
          store
            .dispatch("darkRecon/addPermalinkConversation", [
              drResult.conversation_id,
            ])
            .then((rDarkReconResultPermalink) => {
              checkTest(
                () => {
                  chai.expect(rDarkReconResultPermalink).to.be.a("object");
                },
                `Dark recon conversation ID ${drResult.conversation_id}permalink should be an object`,
                rDarkReconResultPermalink
              );
            });
        }
      });
    const payload = {};
    const params = { q: "bank" };
    darkreconSearchStatsGet(payload, params).then((rDarkReconStats) => {
      checkTest(
        () => {
          chai.expect(rDarkReconStats.data).to.be.a("object");
        },
        "Dark recon stats results should be an object",
        rDarkReconStats.data
      );
    });
  });
  return testCases;
}
