import chai from "chai";
import { formatDate } from "@/utils/common";
import store from "@/store/index";
import moment, { months } from "moment";

export function testClientIdentity() {
  const testCases = [];
  testCases.push((checkTest) => {
    const payload = {
      profile: {
        name: "AutoGenerated Test Customer",
        is_trial: true,
        enabled: true,
        parent_customer: null,
        renewal_date: "2025-01-01",
      },
    };
    store
      .dispatch("clientIdentity/createNewCustomer", payload)
      .then((rCreate) => {
        checkTest(
          () => chai.expect(rCreate.created).to.be.true,
          "Customer creation succeeds",
          rCreate
        );
        let customerId = rCreate.id;

        store.dispatch("clientIdentity/getUsers").then((rUserList) => {
          checkTest(
            () => {
              chai.expect(rUserList).to.be.a("array");
              chai.expect(rUserList[0]).to.be.a("object");
            },
            "User list should be an array of objects",
            rUserList
          );

          checkTest(
            () => {
              for (const user of rUserList) {
                chai.expect(user.email.includes("groupsense.io")).to.be.true;
              }
            },
            "User list should only contain users with groupsense email",
            rUserList
          );

          checkTest(
            () => {
              for (const user of rUserList) {
                chai.expect(user.internal).to.be.true;
              }
            },
            "User list should only contain internal users",
            rUserList
          );

          let user1 = rUserList[0];
          let user2 = rUserList[1];

          payload.external_id = customerId;
          payload.profile.primary_analyst = user1.external_id;
          payload.profile.secondary_analyst = user2.external_id;
          payload.profile.enabled = true;
          payload.profile.is_trial = false;

          store
            .dispatch("clientIdentity/updateCustomer", payload)
            .then((rUpdate) => {
              checkTest(
                () => chai.expect(rUpdate.msg).to.include("Updated"),
                "Profile update message should indicate success",
                rUpdate
              );

              store
                .dispatch("clientIdentity/getProfile", customerId)
                .then((rGet) => {
                  checkTest(
                    () =>
                      chai
                        .expect(rGet.primary_analyst_email)
                        .to.equal(user1.email),
                    "Profile should be updated with primary analyst",
                    rGet
                  );
                  checkTest(
                    () =>
                      chai
                        .expect(rGet.secondary_analyst_email)
                        .to.equal(user2.email),
                    "Profile should be updated with secondary analyst",
                    rGet
                  );

                  checkTest(
                    () => chai.expect(rGet.enabled).to.equal(true),
                    "Profile enabled should be able to be enabled or disabled",
                    rGet
                  );

                  checkTest(
                    () => chai.expect(rGet.is_trial).to.equal(false),
                    "Profile is_trial should be able to be true or false",
                    rGet
                  );

                  checkTest(
                    () => chai.expect(rGet).to.be.a("object"),
                    "Profile should be an object",
                    rGet
                  );

                  checkTest(
                    () => chai.expect(rGet.external_id).to.be.a("string"),
                    "Profile's external ID should be a string",
                    rGet
                  );

                  checkTest(
                    () => chai.expect(rGet).to.not.have.property("id"),
                    "Profile should not have a field called 'id'",
                    rGet
                  );

                  store
                    .dispatch("clientIdentity/getProductsBegin", customerId)
                    .then((rPartners) => {
                      checkTest(
                        () => {
                          chai.expect(rPartners).to.be.a("array");
                        },
                        "Partners data should be array",
                        rPartners
                      );

                      store
                        .dispatch(
                          "clientIdentity/getProductsContinue",
                          rPartners[0].external_id
                        )
                        .then((rProducts) => {
                          checkTest(
                            () => {
                              chai.expect(rProducts).to.be.a("array");
                            },
                            "Products data should be array",
                            rProducts
                          );

                          store
                            .dispatch(
                              "clientIdentity/getProductsEnd",
                              rProducts[0].external_id
                            )
                            .then((rTerms) => {
                              checkTest(
                                () => {
                                  chai.expect(rTerms).to.be.a("array");
                                },
                                "Terms data should be array",
                                rTerms
                              );

                              const product = {
                                name: rProducts[0].external_id,
                                partner: rPartners[0].external_id,
                                start_date: moment().format("YYYY-MM-DD"),
                                end_date: moment()
                                  .add(365, "days")
                                  .format("YYYY-MM-DD"),
                                term: rTerms[0].external_id,
                              };

                              store
                                .dispatch("clientIdentity/createPurchase", {
                                  external_id: customerId,
                                  product: product,
                                })
                                .then((rPurchase) => {
                                  checkTest(
                                    () => {
                                      chai.expect(rPurchase).to.be.a("object");
                                    },
                                    "Purchase record message should be object",
                                    rPurchase
                                  );
                                });
                            });
                        });
                    });
                  const domain = "automated_test_domain.com";
                  store
                    .dispatch("clientIdentity/createEmailDomain", {
                      external_id: customerId,
                      domain: domain,
                    })
                    .then((rData) => {
                      checkTest(
                        () => chai.expect(rData).to.be.a("object"),
                        "Email domain creation response should be object",
                        rData
                      );

                      store
                        .dispatch("clientIdentity/deleteEmailDomain", {
                          external_id: customerId,
                          domain_id: rData.domain_id,
                        })
                        .then((rDelete) => {
                          checkTest(
                            () => chai.expect(rData).to.be.a("object"),
                            "Deleting email domain response should be object",
                            rDelete
                          );
                        });
                    });

                  const user = {
                    email: "automated@test_email.com",
                    first_name: "Automated First Name",
                    last_name: "Automated Last Name",
                  };
                  store
                    .dispatch("clientIdentity/createCustomerUser", {
                      external_id: customerId,
                      user: user,
                    })
                    .then((rData) => {
                      checkTest(
                        () => chai.expect(rData).to.be.a("object"),
                        "Customer user creation response should be object",
                        rData
                      );

                      store
                        .dispatch("clientIdentity/getCustomerUsers", customerId)
                        .then((rData) => {
                          checkTest(
                            () => {
                              for (const user of rData) {
                                chai.expect(
                                  user.email.includes("groupsense.io")
                                ).to.be.false;
                              }
                            },
                            "Customer users should not contain analysts",
                            rUserList
                          );
                        });
                      store
                        .dispatch("clientIdentity/deleteCustomerUserEmail", {
                          external_id: customerId,
                          customer_user_id: rData.customer_user_id,
                        })
                        .then((rDelete) => {
                          checkTest(
                            () => chai.expect(rData).to.be.a("object"),
                            "Deleting customer user response should be object",
                            rDelete
                          );
                        });
                    });

                  store
                    .dispatch("clientIdentity/deleteCustomer", customerId)
                    .then((rDelete) => {
                      checkTest(
                        () => chai.expect(true).to.be.false,
                        "Deleting customer should work",
                        rDelete
                      );
                    });
                });
            });
        });
      });
  });
  return testCases;
}
