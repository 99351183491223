import chai from "chai";
import store from "@/store/index";
import { darkreconAuthorsAuthorGuidConversationsGet } from "@/clients/tracelight";
export function testThreatActors() {
  const testCases = [];
  testCases.push((checkTest) => {
    store
      .dispatch("threatActors/getAuthors", {
        key: "queryString",
        value: "bank",
      })
      .then(function (actors) {
        if (actors.length > 0) {
          checkTest(
            () => {
              chai.expect(actors).to.be.a("array");
              chai.expect(actors[0]).to.be.a("object");
              chai.expect(actors[0]).to.have.property("author_id");
              chai.expect(actors[0]).to.have.property("author_name");
            },
            "Threat actor search results should be an array of objects with properties author_name and author_id",
            actors
          );

          for (let actor of actors) {
            darkreconAuthorsAuthorGuidConversationsGet(
              {},
              {
                author_guid: actor.author_id,
                sort_order: "desc",
                sort_by: "date",
                page: 1,
                results_per_page: 2000,
              }
            )
              .then(function (authorConversations) {
                if (authorConversations.data.length > 0) {
                  checkTest(
                    () => {
                      chai.expect(authorConversations.data).to.be.a("array");
                      chai
                        .expect(authorConversations.data[0])
                        .to.be.a("object");
                      chai
                        .expect(authorConversations.data[0])
                        .to.have.property("messages");
                      chai
                        .expect(
                          authorConversations.data[0].messages[0].author.name
                        )
                        .to.include(actor.author_name);
                    },
                    `Threat actor ${actor.author_name} conversation results should return all conversations`,
                    authorConversations.data
                  );
                }
              })
              .catch(function (error) {
                console.debug(error);
                return;
              });
          }
        }
      })
      .catch(function (error) {
        console.debug(error);
        return;
      });
  });

  return testCases;
}
