import chai from "chai";
import store from "@/store/index";
import { createMonitoringItem } from "@/clients/tracelight";
export function testDigitalFootprint() {
  const testCases = [];
  testCases.push((checkTest) => {
    store.dispatch("customerList/getCustomers").then((rCustomerList) => {
      checkTest(
        () => {
          chai.expect(store.state.customerList.itemTypes).to.be.a("array");
          chai.expect(store.state.customerList.itemTypes[0]).to.be.a("object");
        },
        "Customer items types should be listed in array of objects",
        store.state.customerList.itemTypes
      );

      store
        .dispatch(
          "customerList/getCustomerSummary",
          rCustomerList[0].external_id
        )
        .then((rCustomerSummary) =>
          checkTest(
            () => {
              chai.expect(rCustomerSummary).to.be.a("object");
            },
            "Customer summary should be an object",
            rCustomerSummary
          )
        );

      store
        .dispatch(
          "customerList/getCustomerProgress",
          rCustomerList[0].external_id
        )
        .then((rCustomerProgress) =>
          checkTest(
            () => {
              chai.expect(rCustomerProgress).to.be.a("array");
            },
            "Customer progress should be an array",
            store.state.customerList.progress
          )
        );

      store
        .dispatch(
          "customerList/getCustomerProgressByType",
          rCustomerList[0].external_id
        )
        .then((rCustomerProgressByType) =>
          checkTest(
            () => {
              chai.expect(rCustomerProgressByType).to.be.a("array");
            },
            "Customer progress specified by item type should be an array",
            store.state.customerList.readiness
          )
        );

      store.commit("customerList/replaceValue", {
        key: "customerId",
        value: rCustomerList[0].external_id,
      });

      let itemTypes = [...store.state.customerList.itemTypes];

      function createMonitoringItemsByType(itemTypes) {
        const type = itemTypes.pop();

        createMonitoringItem(
          [
            {
              item_type: type.external_id,
              value: "123.123.123.123",
            },
          ],
          { customer_id: rCustomerList[0].external_id }
        ).then(function (rCreateItem) {
          checkTest(
            () => {
              chai.expect(rCreateItem.data).to.be.a("object");
            },
            `Result from adding monitoring item type ${type.item_type} should be an object`,
            rCreateItem.data
          );
          if (itemTypes.length > 0) {
            createMonitoringItemsByType(itemTypes);
          }
        });
      }

      createMonitoringItemsByType(itemTypes);

      function getMonitoringItemsByType(itemTypes) {
        const type = itemTypes.pop();
        store.commit("customerList/replaceValue", {
          key: "currentItemType",
          value: type.external_id,
        });

        store
          .dispatch("customerList/updateCustomerItems")
          .then((rCustomerItems) => {
            checkTest(
              () => {
                chai.expect(rCustomerItems).to.be.a("array");
                chai
                  .expect(rCustomerItems[0].item_type.item_type)
                  .to.equal(type.item_type);
              },
              `Customer items should return only ${type.item_type} items`,
              rCustomerItems
            );
            if (itemTypes.length > 0) {
              getMonitoringItemsByType(itemTypes);
            }
          });
      }

      getMonitoringItemsByType(itemTypes);
    });
  });

  return testCases;
}
